/*
 *
 * Filename: copyright.tsx
 * Date: 2022-11-03
 * Description: copyright text component
 * Author: Philippe Leroux @ SKITSC
 *
 */


import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
const Copyright = (props : any) : ReactElement => {
    return (
      <Typography sx={{ position : 'fixed' , bottom : 0,right: '50%',left: '50%',transform: 'translate(-50%, -50%)' , width:'500px'}} variant="body2" color="text.secondary" align="center" {...props}>
        {'© '}{new Date().getFullYear()}{' SKITSC. All Rights Reserved.'}
      </Typography>
    );
  }
  
  export default Copyright