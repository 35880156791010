/*
 * Date: 2024
 * Description: Endpoint form logics
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { ReactElement , ChangeEvent } from "react";
import { Box , Typography , TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

//Interfaces
import { i_endpoint_form_props } from "../../interface/endpoint.interface";


const EndpointForm = ( props : i_endpoint_form_props) : ReactElement => {
    const F = props.form
    const E = props.errors
    const handleValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        props.setForm({...F, [name] : value} )
    }
    return(
    <Box component={'form'}>
        <TextField sx={{ marginBottom : '1vh'}} autoFocus label={'Set a pair number'} name="pair_number" helperText={E.pair_number} error={E.pair_number !== ''} fullWidth value={F.pair_number} onChange={handleValue}></TextField>
        <TextField autoFocus label={'Set a note..'} name={'notes'} fullWidth helperText={E.notes} error={E.notes !== ''} value={F.notes} onChange={handleValue}></TextField>
        <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" sx={{ marginTop:'2vh' , display : 'block' , marginLeft : 'auto' , marginRight : 'auto'}}>Submit</LoadingButton>
    </Box>
    )
}

export default EndpointForm;