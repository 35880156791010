/*
 * Date: 2024
 * Description: Custom input ( old )
 * Author: Philippe Leroux @ SKITSC
 */


//Modules
import { TextField } from "@mui/material"
import { ReactElement } from "react"

//Interfaces
import { i_custom_input } from "../../interface/utility.interface"


const CustomInput = ({ err , value , onChange , name , required , type , complete , style , fullWidth  , focus} : i_custom_input) : ReactElement => {
    return (
        <TextField
        error={err.error}
        value={value}
        onChange={onChange}
        sx={style}
        margin="normal"
        required={required}
        fullWidth={fullWidth}
        name={name}
        label={name}
        type={type}
        id={name}
        autoComplete={complete}
        helperText={err.msg}
        focused={focus}
        ></TextField>
    )
}

export default CustomInput