/*
 * Date: 2024
 * Description: Advanced setting for admin panel
 * Author: Philippe Leroux @ SKITSC
 */


//Modules
import { LoadingButton } from "@mui/lab"
import { useContext , useState , useEffect , useRef, ReactElement } from 'react'
import { Paper, Typography , Box , RadioGroup , Radio , FormControlLabel, Button} from "@mui/material"
import { saveAs } from 'file-saver';
//Context
import { SocketContext } from "../../context/socket.context"

//Utils
import { delay } from "../../utility/utility"
import { Fetch, Fetch_File } from "../api/fetch"

//Intefaces
import { i_promise , i_text } from "../../interface/utility.interface";

const AdvancedSetting = ( props : any ) : ReactElement => {
    const [ text , setText ] = useState<i_text[]>([])
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ type , setType ] = useState<'filler'|'download.audio' | string>('filler')
    const [ isRunning , setIsRunning ] = useState<boolean>(false)
    const socket = useContext(SocketContext)
    const bottomRef : any = useRef(null);
    useEffect(() => {
        const initial_fetch = async() => {
            const output : i_promise = await Fetch('/api/script',null,'GET' , props)
            output.data.type === 'filler' && setIsRunning(true); setType('filler')
            output.data.type === 'download.audio' && setIsRunning(true); setType('download.audio')
        }
        initial_fetch();
    },[])
    const HandleAction = async( script : 'filler' | 'download.audio' | string) => {
        var type : 'Start' | 'Kill' = 'Start'
        isRunning ? type = 'Kill' : type = 'Start'
        setLoading(true)
        await delay(1000)
        const output = await Fetch('/api/set/'+type+'/'+script,null,'GET' , props)
        setLoading(false)
        if(output.type === 'Success'){
            isRunning ? setIsRunning(false) : setIsRunning(true)
        }
    }    

    useEffect(() => {    
       socket.removeAllListeners("script");
       socket.on('script', ( script_pipe ) => {
        var new_row = [...text]
        var formated_row : i_text = {
            data : script_pipe.data === 'false' ? 'Script interupted' : script_pipe.data,
            type : script_pipe.type,
            key : script_pipe.count
        }
        new_row.push(formated_row)
        setText(new_row)
        script_pipe.data === 'false' ? setIsRunning(false) : script_pipe.data === 'error' && setIsRunning(false)
    })  
    },[socket , text])
   
    const normal = {
        color : 'green'
    }
    const close = {
        color : 'orange'
    }
    const error = {
        color : 'red'
    }

    useEffect(() => {
        bottomRef !== null && bottomRef.current !== null && bottomRef.current.scrollIntoView({behavior: 'smooth'})
    }, [text]);
    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setType((event.target as HTMLInputElement).value)
    }
    const handleBackup = async(type : string) => {
        const data = await Fetch_File('/api/backup/'+type , 'GET')
        var formated_row : i_text = {
            data : "Heres is backup file..",
            type : 'data',
            key : 0
        } 
        const current_text = [...text]
        if(data !== undefined && data.status === 200){
            const blob = await data.blob()
            const fileName = 'backup.'+type; // Set the desired filename
            saveAs(blob, fileName); // Save the blob data as a file
        }else{
           
            formated_row = {
                data : "File not found ! please make a backup first !",
                type : 'error',
                key : 0
            }
        }
        current_text.push(formated_row)
        setText(current_text)
    }
    return(
        <Paper style={{ height : '35vh'}}>
            <Typography sx={{fontWeight : 600 , textAlign : 'center'}}>{isRunning ? 
            ( type === 'filler' ? 'Database records hydration is running ...' : 
              type === 'download.audio' ?'Download audio files is running ...' :
              type === 'backup' && 'Generating a json..' ) : 'Execute scripts'}</Typography>
            { !isRunning &&
            <Box sx={{ display : 'flex' , width : '100%' , justifyContent : 'center'}}>
                <RadioGroup name="radio-buttons-group-scripts" onChange={handleChangeType} value={type} row>
                    <FormControlLabel value={'filler'} control={<Radio />} label="Database records hydration" />
                    <FormControlLabel value={'sip'} control={<Radio />} label="Database sip hydration" />
                    <FormControlLabel value={'download.audio'} control={<Radio />} label="Download audio files" />
                    <FormControlLabel value={'backup.csv'} control={<Radio />} label="Backup in a csv file" />
                    <FormControlLabel value={'backup'} control={<Radio />} label="Backup in a json file" />

                </RadioGroup>
            </Box>
            }   
            <div style={{ display : 'flex' , justifyContent : 'center'}}>
                <LoadingButton style={{width : '40%' , fontWeight : 600}} variant="contained" color={isRunning ? 'secondary' : 'primary'} loading={loading} onClick={() => HandleAction(type)}>
                    {isRunning ? 'Stop' : 'Start script' }
                </LoadingButton>
            </div>
            <Paper style={{ margin : '1em'}}>
                <Box style={{ display : 'block' , overflow : 'scroll' , height : '12vh', backgroundColor : 'black' , width : '95%' , padding : '1em' }}>
                    { text.map(( row : any , i : number) => (
                        <div ref={bottomRef} key={i} style={{width : '100%'}}>
                              <Typography key={i} style={row.type === 'data' ? normal : row.type === 'close' ? close : row.type === 'error' ? error : normal}>{row.data}</Typography>
                        </div>
                    ))}
                </Box>
            </Paper>
            <Box sx={{ display : 'block' , width : '100%' , padding : '1em' , justifyContent : 'center'}}>
                <Box component={'div'} sx={{ textAlign : 'center' , width : '100%'}}>Make sure to execute backup script in first!</Box>
                <Box sx={{ display : 'flex' , width : '100%' ,  justifyContent : 'space-evenly' , marginTop:'1vh'}}>
                    <Button variant="contained" sx={{ fontWeight : 600 , width : '40%'}} onClick={() => handleBackup('json')}>Download json backup!</Button>
                    <Button variant="contained" sx={{ fontWeight : 600 , width : '40%'}} onClick={() => handleBackup('csv')}>Download csv backup!</Button>
                </Box>
            </Box>       
        </Paper>
    )
}
export default AdvancedSetting