/*
 * Date: 2024
 * Description: Handle new and update domain
 * Author: Philippe Leroux @ SKITSC
 */

//Modules
import { useState , useEffect , useContext, ReactElement } from 'react';
import { Paper , Box, Typography  } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Interfaces
import { i_promise , i_alert , i_error_input } from '../../interface/utility.interface';
import { i_sent_domain } from '../../interface/domain.interface';

//Utils
import { f_generate_empty_promise } from '../../utility/utility';
import { Fetch } from '../api/fetch';

//Middlewares
import { m_valid_name , m_valid_address} from '../utils/field.validation';

//Components
import PromptAlert from '../utils/alert';
import CustomInput from './custom.input';

//Styles
import { field_style } from '../styles/styles';

//Context
import { ManagementContext } from '../../context/management.context';
import { MainContext } from '../../context/main.context';

const DomainForm = (props : any) : ReactElement => { 
    const { selected_domain } = useContext(ManagementContext)
    const { setUser , user } = useContext(MainContext)
    const [ id , setId ] = useState<number>(0)
    const [ note , setNote] = useState<string>('')
    const [ address , setAddress] = useState<string>('')
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ alert , setAlert ] = useState<boolean>(false)
    const [ alert_msg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'});
    const [ err_address , setErrAddress] = useState<i_error_input>({error : false , msg : ''});
    const [ err_note , setErrNote] = useState<i_error_input>({error : false , msg : ''});

    const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value)
        if(err_note) setErrNote({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value)
        if(err_address) setErrAddress({error : false , msg : ''});
        handleAlertreset();
    }
    const handleAlertreset = () => {
        setAlert(false);
        setAlertmsg({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'})
    }
    const handleSubmit = async( event : any) => {
        event.preventDefault();
        var output : i_promise = f_generate_empty_promise();
        const data : i_sent_domain = {
            id: id,
            address : undefined,
            note : undefined,
            timestamp : undefined
        }
        const valid_address = m_valid_address(address);
        if(valid_address !== '') return setErrAddress({ error : true , msg : valid_address})
        data.address = address
        if(note !== ''){
            const valid_note = m_valid_name(note)
            if(valid_note !== '') return setErrNote({ error : true , msg : valid_note})
        }
        data.note = note
        setLoading(true)
        var method : 'POST' | 'PUT' = 'POST'
        if(data.id > 0) method = 'PUT'
        output = await Fetch("/api/domains", data , method , props);
        setLoading(false)
        if(output.type === 'Success'){    
            if(output.data.id === user.id && method === 'PUT') setUser(output.data)
            props.setAlertmsg({msg : output.message , severity : 'success' , setAlert : setAlert , position : 'page'})
            props.setAlert(true)
            props.handleClose(output)
            props.handleRerender('domain')
        }else{
            setAlertmsg({msg : output.message , severity : 'error' , setAlert : setAlert , position:'modal'})
            setAlert(true)
        }
    }
    useEffect(() => {
        setId(selected_domain.id)
        setAddress(selected_domain.address)
        setNote(selected_domain.note)
    },[selected_domain])
    return (
        <Paper sx={{ padding:'1vh'}}>
            <Typography textAlign={'center'} sx={{fontWeight:'800', margin:'1vh'}}>{selected_domain.id === 0 ? 'Create a domain access' : 'Update a domain entry' }</Typography>
            <Box component={'form'} onSubmit={handleSubmit} autoComplete='off'>
                {alert ? <PromptAlert {...alert_msg}></PromptAlert> : null}     
                <CustomInput value={address} onChange={handleChangeAddress} err={err_address} name={'address'} type={'text'} style={field_style} required={true} fullWidth={true} complete={'current-address'} ></CustomInput>
                <Typography>Optional fields **</Typography>
                <CustomInput value={note} onChange={handleChangeNote} err={err_note} name={'note'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <LoadingButton type="submit" fullWidth variant="contained" color='primary' loading={loading}sx={{ mt: 3, mb: 2 }}>Submit</LoadingButton> 
            </Box>

        </Paper>
    )
}

export default DomainForm