/*
 *
 * Filename: record.tsx
 * Date: 2022-11-07
 * Description: Page where the user can filters and navigate threw all records.
 * Author: Philippe Leroux @ SKITSC
 *
 */
//Majors modules
import { useState , useContext , useEffect , useRef , useMemo } from 'react'
import { SocketContext } from '../context/socket.context';
import { ThemeProvider , Paper , Box } from "@mui/material"
import { useNavigate } from 'react-router-dom';
import { c_theme } from "../theme/custom.theme"
import ReactAudioPlayer from 'react-audio-player';
//Components && imports.
import PagesComponent from "../components/table/fitler.table.pagination";
import TableSSR from "../components/table/filter.table.records";
import Filters from "../components/table/filters.records";
import { Fetch, Fetch_File } from '../components/api/fetch';
import { delay, f_format_phone_cute, f_timestamp_to_date , findEndpoint } from "../utility/utility";
import PromptAlert from '../components/utils/alert';
import ModalForm from '../components/modal/modal';

//Interfaces && types
import { i_records } from '../interface/records.interface';
import { i_filter_records } from "../interface/records.interface";
import { i_note_form_props , i_contact } from '../interface/utility.interface';
import { i_alert } from '../interface/utility.interface';

//Constants
import { default_search , default_target } from '../utility/constant';
import { MainContext } from '../context/main.context';
import { i_endpoint } from '../interface/endpoint.interface';

const default_delete_title : string = 'Are you sure to delete record with the id : '
const default_edit_title : string = 'Edit note for ID : '
const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return screenWidth;
};
const Records = ( props : any ) => {

    const { HandleLogout } = useContext(MainContext)    
    const socket = useContext(SocketContext)
    const nav = useNavigate()
    const [ src , setSrc ] = useState<string>('')
    const [ alert , setAlert ] = useState<boolean>(false)
    const [ search , setSearch ] = useState<i_filter_records>(default_search)
    const [ data , setData ] = useState<i_records[]>([])
    const [ endpoints , setEndpoints ] = useState<i_endpoint[]>([])
    const [ note , setNote ] = useState<string>('')
    const [ count , setCount ] = useState<number>(0)
    const [ open , setOpen ] = useState<boolean>(false)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ target , setTarget ] = useState<i_records>(default_target)
    const [ isPlaying , setIsPlaying ] = useState<boolean>(false)
    const [ title , setTitle ] = useState<string>('')
    const [ searchFrom , setSearchFrom ] = useState<string>('')
    const [ searchTo , setSearchTo ] = useState<string>('')
    const [ event , setEvent ] = useState<'delete_record' | 'edit_record'>('delete_record')
    const [ alert_msg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'});
    const [ fetch_loading , setFetchLoading ] = useState<boolean>(false)
    const [ fromArr , setFromArr ] = useState<i_contact[]>([])
    const [ toArr , setToArr ] = useState<i_contact[]>([])

    const handlePlay = ( row : i_records ) => {
        const true_path = process.env.REACT_APP_API_URL+'/api/file/'+row.add_time+'/'+row.path
        setTarget(row)
        setSrc(true_path)
        handlePlayPause()
    } 
    const handleClose = () => setOpen(false)

    useEffect(() => {
        socket.removeAllListeners("new_call");
        socket.on('new_call', ( row ) => {
            var x : number = 0;
            if(search.pages === 0)
            for(x < row.returned_rows.length;x++;){
                const data_to_add = [ ...data ] 
                const objectExists = data_to_add.some(( obj : i_records ) => obj.call_uuid === row.call_uuid);
                if(!objectExists) {
                    data_to_add.push(row.returned_rows[x])
                    setData(data_to_add)
                }    
            }
        })
    },[])

    useEffect(() => {
        const fetchEndpoints = async () => {
            const res = await Fetch('/api/endpoints' ,null, "GET" , props)
            if(res.type === "Success") {
                setEndpoints(res.data)
                return res.data
            }else{
                if(res.type === 'Unauthorized'){
                    HandleLogout()
                    nav('/')
                
                }else{
                    setAlert(true)
                    setAlertmsg({msg : res.message , severity : 'error' , setAlert : setAlert , position : 'page'})
                    return []
                }
            }
        }
        const fetchContacts = async ( E : i_endpoint[]) => {
            await delay(200)
            const res = await Fetch('/api/records/contacts' ,null, "GET" , props)
            if(res.type === "Success") {
                const formated_from = []
                const formated_to = []
                for(var x =0; x < res.data.from.length; x++){
                    if(res.data.from[x].number !== 'none' && res.data.from[x].number !== null){
                        const num = findEndpoint(res.data.from[x].number , 'in' , E)
                        if(num.length ===0) console.log(num)
                        formated_from.push({ number : num, count : res.data.from[x].count })
                    }
                }
                setFromArr(formated_from)
                for(var y : number =0; y < res.data.to.length; y++){
                    if(res.data.to[y].number !== 'none' && res.data.to[y].number !== null){
                        formated_to.push({ number : res.data.to[y].number, count : res.data.to[y].count })
                    }
                }
                setToArr(formated_to)
            }
            setLoading(false)
        }
        const main = async() => {
            const E = await fetchEndpoints()
            await fetchContacts(E)
        }
        main()
    },[props , nav , HandleLogout])
    function removeSpecialChars(inputString: string): string {
        var clean = inputString.replace(/[-()]/g, '');
        return clean
      }
    useEffect(() => {
        setLoading(true)
        const f_fetch_rows = async () => {
            const f_encodeQueryData = () =>  { return new URLSearchParams({...search, pages : search.pages.toString() , 
                offset : search.offset.toString() , from_number : removeSpecialChars(search.from_number) ,to_number :  removeSpecialChars(search.to_number) , timestamp : search.timestamp }) }
            const res = await Fetch('/api/records/filtered?'+f_encodeQueryData() ,null, "GET" , props)
            if(res.type === "Success") {
                setData(res.data.records)
                setCount(res.data.count)
            }else{
                if(res.type === 'Unauthorized'){
                    HandleLogout()
                    nav('/')
                }
            }
            setLoading(false)
        }

        f_fetch_rows()
    },[search , props , HandleLogout , nav])

    const handleAction = async() => {
        setFetchLoading(true)
        const f_api_delete = async(row : i_records) => {
            await delay(500)
            const output = await Fetch('/api/records/'+row.id , null , 'DELETE' , props)
            if(output.type === 'Success'){
                var current_rows : Array<i_records> = [...data]
                const desactivated_row = current_rows.findIndex( i => i.id === row.id)
                current_rows.splice(desactivated_row,1)
                setData(current_rows)
                handleClose();
                setAlert(true)
                setAlertmsg({msg : 'Record id : ' + row.id + ' desactivated.' , severity : 'success' , setAlert : setAlert , position : 'page'})
            }
        }
        const f_api_update = async(row : i_records) => {
            await delay(500)
            const new_note = row
            new_note.note = note
            const updated_row = await Fetch('/api/records/note' , new_note , 'PATCH' , props )
            setAlert(true)
            setAlertmsg({msg : 'Note id : ' + row.id + ' updated.', severity : updated_row.type === "Success" ? 'success' : 'error', setAlert : setAlert, position : 'page'})
        }
            
        if(event === 'delete_record')await f_api_delete(target)
        if(event === 'edit_record') await f_api_update(target)
        if(open) setOpen(false)
        setFetchLoading(false)
    }
    const handleDownload = async(row : i_records) => {
        const res = await Fetch_File('/api/file/'+row.add_time+'/'+row.path , "GET")
        if(res !== undefined){
            const blob = await res.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = "D-"+f_timestamp_to_date(row.add_time , 'short') + '-F-' + f_format_phone_cute(row.from_number) + '-T-' + f_format_phone_cute(row.to_number) + '.mp3';
            a.href = url;
            document.body.appendChild(a);
            a.click();
        }
    }
    const setDelete = ( row : i_records ) : void => {
        setTarget(row)
        setEvent('delete_record')
        setTitle(default_delete_title+row.id)
        setOpen(true)
    }
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) : void => { 
        const new_search : i_filter_records = {...search}
        new_search.pages = newPage
        setSearch(new_search)
    };
        const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
        const new_search : i_filter_records = {...search}
        new_search.pages = 0
        new_search.offset = parseInt(event.target.value, 10)
        setSearch(new_search)
        };
    const handleEdit = (row : i_records) => {
        setTarget(row)
        setNote(row.note)
        setEvent('edit_record')
        setTitle(default_edit_title+row.id)
        setOpen(true)
    }
        
    const audioRef = useRef<ReactAudioPlayer>(null);
    const handlePlayPause = () => {
        if (audioRef.current) {
            const audioElement = audioRef.current.audioEl.current;
            if (isPlaying) {
            audioElement !== null && audioElement.pause();
            } else {
            audioElement !== null && audioElement.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    const handleAudioPlay = () : void => setIsPlaying(true);
    const handleAudioPause = () : void =>  setIsPlaying(false);
    const handleTimestamp = () : void => {
        const current_search = {...search}
        if(search.timestamp === 'ASC'){
            current_search.timestamp = 'DESC'
            setSearch(current_search)
        }else{
            current_search.timestamp = 'ASC'
            setSearch(current_search)
        }
    }
    const clearFilter = () : void => setSearch(default_search)
    const handleChangeFrom = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const current_search : i_filter_records = {...search}
        current_search.pages = 0
        event.target.value.length > 0 ? current_search.from_number = findEndpoint(event.target.value , 'out' , endpoints) : current_search.from_number = ''
        setSearch(current_search)
    }
    const handleChangeTo = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const current_search : i_filter_records = {...search}
        current_search.to_number = event.target.value
        current_search.pages = 0
        setSearch(current_search)
    }
    const handleChangeNotes = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const current_search : i_filter_records = {...search}
        current_search.note = event.target.value
        setSearch(current_search)
    }
    const handleChangeCallUUID = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const current_search : i_filter_records = {...search}
        current_search.call_uuid = event.target.value
        setSearch(current_search)
    }
    const handleSearchFrom = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchFrom(event.target.value)
    }
    const containsText = (text : string, search : string) =>  text.toLowerCase().indexOf(search.toLowerCase()) > -1 
    const displayedOptionsFrom = useMemo(
        () => fromArr.filter((option : i_contact) => option !== null ? containsText(option.number, searchFrom) : () => {}),
        [searchFrom , fromArr]
        );
        const displayedOptionsTo = useMemo(
        () => toArr.filter((option : i_contact) => option !== null ? containsText(option.number, searchTo) : () => {}),
        [searchTo , toArr]
    );
    const TableProps = {
        data : data,
        endpoints : endpoints,
        search : search,
        count : count,
        loading : loading,
        setData : setData,
        setSearch : setSearch,
        alert : alert,
        setAlert : setAlert,
        setDelete : setDelete,
        handleEdit : handleEdit,
        handlePlay : handlePlay,
        handleDownload : handleDownload,
        src : src,
        target : target,
        isPlaying : isPlaying,
        handleTimestamp : handleTimestamp,
        clearFilter : clearFilter,
        handleChangeTo : handleChangeTo,
        handleChangeFrom : handleChangeFrom,
        handleChangeNote : handleChangeNotes,
        handleChangeCallUUID : handleChangeCallUUID,
        handleClose : handleClose,
        searchFrom : searchFrom,
        searchTo : searchTo,
        handleSearchFrom : handleSearchFrom,
        setSearchFrom : setSearchFrom,
        setSearchTo : setSearchTo,
        
        displayedOptionsFrom : displayedOptionsFrom,
        displayedOptionsTo : displayedOptionsTo,
        fromArr : fromArr,
    }

    const note_form_props : i_note_form_props = {
        title : title,
        note : note,
        setNote : setNote,
        loading : fetch_loading,
        callback : handleAction,
    }

    const modal_props = {
        open : open,
        event: event,
        data : target,
        Action : handleAction,
        title : title,
        loading : fetch_loading,
        handleClose : handleClose,
        note_form_props : note_form_props,
    }
    const screenWidth = useScreenWidth();
    return (
        <ThemeProvider theme={c_theme}>
            {alert && <PromptAlert {...alert_msg}></PromptAlert> }
                <Paper sx={{padding : '3vh' , mt : '3vh' , maxWidth : 1300 , display : 'block', marginLeft : 'auto' , marginRight : 'auto' , height: 1050}}>
                    <Filters {...TableProps}></Filters>
                    <TableSSR {...TableProps}></TableSSR>
                    <PagesComponent handleChangeRowsPerPage={handleChangeRowsPerPage} search={search} page={search.pages} rowsPerPage={search.offset} count={count} onPageChange={handleChangePage}></PagesComponent>
                </Paper>
                    <Box sx={{ position : 'fixed' , bottom : 0 , zIndex : 10000000}}>
                        <ReactAudioPlayer src={src} style={{ width : screenWidth+'px'}} autoPlay controls={true} ref={audioRef} onPlay={handleAudioPlay} onPause={handleAudioPause}/> 
                    </Box>
            <ModalForm {...modal_props}></ModalForm>
        </ThemeProvider>
    )
}
export default Records