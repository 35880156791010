/*
 *
 * Filename: forgotpassword.tsx
 * Date: 2022-11-09
 * Description: Page where user can change its password.
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, Paper , Typography , Container , CssBaseline , Box , TextField , Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from '@mui/material/styles';

//Interfaces
import { i_promise , i_error_input , i_alert } from "../interface/utility.interface";

//Component
import Copyright from "../components/copyright";
import PromptAlert from "../components/utils/alert";

//Themes
import { c_theme } from "../theme/custom.theme";

//Validation
import { m_valid_email , m_valid_password , m_valid_code } from "../components/utils/field.validation";

//Utils
import { Fetch } from "../components/api/fetch";

const ForgotPassword = ( props : any ) => {

    const [ alert , setAlert ] = useState<boolean>(false)
    const [ step , setStep ] = useState<number>(1)
    const [ email_code , setEmailCode ] = useState<string>('')
    const [ id , setId ] = useState<number>(0)
    const [ alert_msg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'login'});
    const [ email , setEmail ] = useState<string>('')
    const [ password , setPassword ] = useState<string>('')
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ err_field , setErrfield ] = useState<i_error_input>({error : false , msg : ''});

    const navigate = useNavigate();

    const handleRedirect = (event : any) => {
        event.preventDefault();
        navigate('/login')
    }

    const handleSubmit = async(event : any) => {
        handleAlertreset();
        event.preventDefault(); 
        var data; var valid_field : string = 'empty'; var output : i_promise; var endpoint : 'password' | 'code' | 'email' = 'email';
        setLoading(true)
        if(step === 1){
            endpoint = 'email'
            data = { email : email }
            valid_field = m_valid_email(email)
        }
        if(step === 2){
            endpoint = 'code'
            data = { code : email_code , id : id }
            valid_field = m_valid_code(email_code)
        }
        if(step === 3){
            endpoint = 'password'
            data = { password : password , id : id }
            valid_field = m_valid_password(password)
        }            
        if(valid_field === ''){
            output = await Fetch('/api/recovery/'+endpoint , data , 'POST' , props);
            if(output.type === 'Success'){
                if(step === 1) setId(output.data)
                var new_step = step + 1
                setStep(new_step)
                setAlertmsg({msg : output.message , severity : 'success' , setAlert : setAlert , position : 'login'})
                setAlert(true)
            }else{
                setAlertmsg({msg : output.message , severity : 'error' , setAlert : setAlert , position : 'login'})
                setAlert(true)
            }
        }else{
            if(valid_field !== 'empty'){
                setErrfield({error : true , msg : valid_field })
            }
        }
        setLoading(false)
    }    
    const handleAlertreset = () => {
        setAlert(false);
        setAlertmsg({msg : '' , severity : 'error' , setAlert : setAlert , position:'login'})
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        if(err_field) setErrfield({error : false , msg : ''});
        handleAlertreset();
    };
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        if(err_field) setErrfield({ error : false , msg : '' });
        handleAlertreset();
    };
    const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailCode(event.target.value);
        if(err_field) setErrfield({ error : false , msg : '' });
        handleAlertreset();
    };
    //Trigger form on press enters
    const handleKeyDown = ( event : any ) => {
        if (event.keyCode === 13) handleSubmit(event);
    }
    const steps_str = [
        '',
        'Please give your email to receive recovery code.',
        'Insert the code you received in your email.',
        'Create a new password.'
    ]
    const button_styles = {
        marginTop : '5vh'
    }
    return  (
        <ThemeProvider theme={c_theme}> 
            {alert ? <PromptAlert {...alert_msg}></PromptAlert> : null}
            <Container style={{height : '100vh'}} component="main" maxWidth="sm">
            <CssBaseline />
                <Box component={'div'} height="20vh"></Box>
                    <Paper sx={{ height : 450 , padding : '1vh'}} elevation={4}>
                        <Box component={'div'} sx={{position : 'absolute', color:'#d9b451'}}>{step}/4</Box>
                            <Box sx={{display: 'flex',flexDirection: 'column',alignItems: 'center' }}>
                                <img src='/img/sm-logo.png' alt='skitc logo' className='img'/> 
                                <Typography component="h1" variant="h5">
                                Recover your password
                                </Typography>
                                <Box component="form"  onSubmit={e => { e.preventDefault(); }} onKeyDown={ (e) => { handleKeyDown(e)}} sx={{ mt: 1 }}>
                                    <Typography marginTop={2} textAlign='center'>{steps_str[step]}</Typography>
                                    { step === 1 ? 
                                <TextField
                                    error={err_field.error}
                                    value={email}
                                    onChange={handleChangeEmail}
                                    sx={{backgroundColor : 'white'}}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    helperText={err_field.msg}
                                />
                                : step === 2 ?
                                <TextField
                                    error={err_field.error}
                                    value={email_code}
                                    onChange={handleChangeCode}
                                    sx={{backgroundColor : 'white'}}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="code"
                                    label="code"
                                    type="code"
                                    id="code"
                                    autoComplete="current-code"
                                    helperText={err_field.msg}
                                />
                                : step === 3 && 
                                <TextField
                                error={err_field.error}
                                value={password}
                                onChange={handleChangePassword}
                                sx={{backgroundColor : 'white'}}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                helperText={err_field.msg}
                                />
                                }
                                {step !== 4 &&
                                    <LoadingButton
                                        onClick={(event) => handleSubmit(event)}
                                        fullWidth
                                        variant="contained"
                                        color='primary'
                                        loading={loading}
                                        sx={{ mt: 3, mb: 2 }}
                                        >
                                        Submit
                                    </LoadingButton>
                                }
                            <Grid container>
                                <Grid item xs>
                                    <Button variant={step === 4 ? "contained" : "text"} sx={step === 4 ? button_styles : null} onClick={(event) => handleRedirect(event)}>Go to login</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
                

    )
}

export default ForgotPassword;