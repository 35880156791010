/*
 * Date: 2024
 * Description: password form handling , contains logic coverage
 * Author: Philippe Leroux @ SKITSC
 */

//Modules
import { ReactElement, useState } from 'react';
import { Paper , Box, Typography  } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Interfaces
import { i_error_input , i_promise , i_alert } from '../../interface/utility.interface';

//Utils
import { Fetch } from '../api/fetch';
import { f_generate_empty_promise } from '../../utility/utility';

//Validation
import { m_valid_password , m_valid_Cpassword } from '../utils/field.validation';

//Components
import CustomInput from './custom.input';
import PromptAlert from '../utils/alert';

//Styles
import { field_style } from '../styles/styles';

const PasswordForm = (props : any) : ReactElement => {
    const [ password , setPassword] = useState<string>('')
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ alert , setAlert ] = useState<boolean>(false)
    const [ alert_msg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'});
    const [ confirmPassword ,setConfirmPassword] = useState<string>('')
    const [ err_password , setErrpassword] = useState<i_error_input>({error : false , msg : ''});
    const [ err_c_password , setErrCpassword] = useState<i_error_input>({error : false , msg : ''});
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
        if(err_password) setErrpassword({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value)
        if(err_c_password) setErrCpassword({error : false , msg : ''});
        handleAlertreset();
    }
    const handleAlertreset = () => {
        setAlert(false);
        setAlertmsg({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'})
    }
    const handleSubmit = async( event : any) => {
        event.preventDefault();
        var output : i_promise = f_generate_empty_promise();
        const valid_password = m_valid_password(password);
        const valid_Cpassword = m_valid_Cpassword(password , confirmPassword) 
        const data = {
            password : password,
            id : props.user.id
        }
        if(valid_password === '' && valid_Cpassword === ''){
            setLoading(true)
            output = await Fetch("/api/users/password", data , 'PATCH' , props);
            setLoading(false)
            if(output.type === 'Success'){
                props.handleClose(output)
                props.setAlertmsg({msg : output.message , severity : 'success' , setAlert : setAlert , position : 'page'})
                props.setAlert(true)
            }else{
                setPassword('');
                setConfirmPassword('');
                setAlertmsg({msg : output.message , severity : 'error' , setAlert : setAlert , position:'modal'})
                setAlert(true)
            }
        }else{
            if(valid_password !== '') setErrpassword({ error : true , msg : valid_password})
            if(valid_Cpassword !== '') setErrCpassword({ error : true , msg : valid_Cpassword})
        }
    }
    return (
        <Paper sx={{ padding:'1vh'}}>
            <Typography textAlign={'center'} sx={{fontWeight:'800', margin:'1vh'}}>Update your password</Typography>
            <Box component={'form'} onSubmit={handleSubmit}>
            {alert ? <PromptAlert {...alert_msg}></PromptAlert> : null}                
                <CustomInput value={password} onChange={handleChangePassword} err={err_password} name={'password'} type={'password'} style={field_style} required={true} fullWidth={true} complete={'current-password'} ></CustomInput>
                <CustomInput value={confirmPassword} onChange={handleChangeConfirmPassword} err={err_c_password} name={'Confirm password'} type={'password'} style={field_style} required={true} fullWidth={true} complete={'current-password'} ></CustomInput>
                <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color='primary'
                loading={loading}
                sx={{ mt: 3, mb: 2 }}
                >Submit</LoadingButton> 
            </Box>

        </Paper>
    )
}

export default PasswordForm