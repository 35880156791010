/*
 *
 * Filename: welcome.tsx
 * Date: 2022-11-04
 * Description: Transition page , that shows a loading on token validation.
 * Author: Philippe Leroux @ SKITSC
 *
 */
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { c_theme } from "../theme/custom.theme";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/private-theming";
const Welcome = ( props : any) => {
    const nav = useNavigate();
    useEffect(() => {
        if(props.loaded && props.auth) nav('/dashboard')
        if(props.loaded && !props.auth) nav('/login')
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.loaded]) 

    return (
        <ThemeProvider theme={c_theme}>
            <Container style={{height : '100vh'}} component="main" maxWidth="xs">
            <Box sx={{ display: 'flex' }}>
                <CircularProgress size="7.5em" sx={{color : "#d9b451",marginLeft:'auto' , marginRight:'auto', marginTop:'42.5vh'}}/>
            </Box>
            </Container>
        </ThemeProvider>
    )
}
export default Welcome