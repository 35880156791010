import React, { createContext } from 'react';
import { io, Socket } from 'socket.io-client';
var path = 'ws://localhost:5000'
if(process.env.REACT_APP_WS !== undefined) path = process.env.REACT_APP_WS

const socket = path.includes('http:') ? 
io(path,{
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd"
    }
}) 
: 
io({
    path : path,
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd"
    }
}) ,

SocketContext = createContext<Socket>(socket);

socket.on('connect', () => {
    console.log('Socket Connected!')
});  
socket.on('error',(err) => console.log(err))
const SocketProvider = ({ children }: any) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };