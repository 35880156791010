/*
 * Date: 2024
 * Description: Main modal body triggers events. Need rework for ts..
 * Author: Philippe Leroux @ SKITSC
 */


//Modules
import { Modal , IconButton , Typography , Box } from '@mui/material';
import { ReactElement } from 'react';

//Forms
import PasswordForm from '../form/password.form';
import EmailForm from '../form/email.form';
import YesNoForm from '../form/yesno.form';
import UserForm from '../form/user.form';
import DomainForm from '../form/domain.form';
import MailForm from '../form/mail.form';
import NoteForm from '../form/note.form';
import EndpointInfo from './endpoint.info.body';
import EndpointForm from '../form/endpoint.form';

//Icons 
import { Close } from "@mui/icons-material";

//Styles
import { style } from '../../styles/modal';

const ModalForm = (props : any) : ReactElement => {
  return (
    <div>
      <Modal open={props.open} onClose={props.handleClose} aria-labelledby="modal" aria-describedby="event-modal">
        <Box>
            <Box sx={style}>
                {props.new_close &&
                    <Box sx={{ display : 'flex' , marginBottom : '2vh'}}>
                        <Typography sx={{ fontSize : 20 , fontWeight : 600 , alignSelf : 'center'}}>{props.title}</Typography>
                        <IconButton onClick={() => props.handleClose()} sx={{ marginLeft : 'auto'}}><Close/></IconButton>
                    </Box>
                }

                {props.event === 'password' && <PasswordForm {...props}/> }
                {props.event === 'email' && <EmailForm {...props} />}
                {props.event === 'endpoint' && <EndpointForm {...props.form}/>}
                {props.event === 'endpoint_info' && <EndpointInfo {...props.form}/> }
                {props.event === 'update_smtp' ? <MailForm {...props}/> : null}
                {props.event === 'insert_user' || props.event === 'update_user' ? <UserForm {...props}/> : null}
                {props.event === 'insert_domain' || props.event === 'update_domain' ? <DomainForm {...props}/> : null}
                {props.event === 'edit_record' ? <NoteForm {...props.note_form_props}/> : null}
                {props.event === 'delete_record' || props.event === 'delete_user' || props.event === 'delete_domain' ? <YesNoForm title={props.title} loading={props.loading} Action={props.Action} handleClose={props.handleClose}/> : null}
            </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalForm;
