/*
 * Date: 2024
 * Description: Records note form
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { ReactElement } from "react";
import { Box , Typography , TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

//Interfaces
import { i_note_form_props } from "../../interface/utility.interface";


const NoteForm = ( props : i_note_form_props ) : ReactElement => {
    return(
        <Box component={'form'} onSubmit={props.callback}>
        <Typography variant={'h6'} component={'h6'} align="center" sx={{ marginBottom : '2vh'}}>{props.title}</Typography>
        <TextField autoFocus label={'Set a note :'} fullWidth value={props.note} onChange={( e ) => props.setNote(e.target.value) }></TextField>
        <LoadingButton type="submit" loading={props.loading} variant="contained" sx={{ marginTop:'2vh' , display : 'block' , marginLeft : 'auto' , marginRight : 'auto'}}>Submit</LoadingButton>
    </Box>
    )
}

export default NoteForm;