import { Box , Typography , IconButton , TextField , InputLabel , FormControl , Select , MenuItem , InputAdornment ,ListSubheader  } from "@mui/material"
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";

import { findEndpoint } from "../../utility/utility";

const Filters = ( props : any) => {
    return(
        <Box display={'flex'} sx={{ height : 60}}>
            <Typography sx={{ alignSelf : 'center'}}>Index </Typography>
            <IconButton onClick={() => props.handleTimestamp()} sx={{ alignContent : 'center' , marginRight : '1vh'}}> {props.search.timestamp === 'ASC' ? <TrendingDownIcon/> : <TrendingUpIcon/> }</IconButton>
            <FormControl sx={{ width : '15em' , marginRight : '1vh'}} >
                <InputLabel id="search-select-label">From :</InputLabel>
                    <Select MenuProps={{ autoFocus: false }}labelId="Search-records"id="search-select" label="Options"
                    value={props.search.from_number} onChange={(e) =>  props.handleChangeFrom(e)} onClose={() => props.setSearchFrom("")} renderValue={() =>  findEndpoint(props.search.from_number , 'render' , props.endpoints )} >
                        <ListSubheader>
                            <TextField
                            size="small"
                            autoFocus
                            placeholder="Type to search..."
                            
                            InputProps={{
                                startAdornment: ( <InputAdornment position="start"> <SearchIcon /></InputAdornment>)
                            }}
                            onChange={(e) => props.setSearchFrom(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                e.stopPropagation();
                                }
                            }}
                            />
                        </ListSubheader>
                        {props.displayedOptionsFrom.slice(0,20).map((option : any, i : number) => (
                            <MenuItem key={i} value={option.number}> {option.number} </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl sx={{ width : '15em' , marginRight : '1vh'}} >
                <InputLabel id="search-select-label">To :</InputLabel>
                    <Select MenuProps={{ autoFocus: false }}labelId="Search-records"id="search-select" label="Options"
                    value={props.search.to_number} onChange={(e) =>  props.handleChangeTo(e)} 
                    onClose={() => props.setSearchTo("")}renderValue={() => props.search.to_number} >
                        <ListSubheader>
                            <TextField
                            size="small"
                            autoFocus
                            placeholder="Type to search..."
                            
                            InputProps={{
                                startAdornment: ( <InputAdornment position="start"> <SearchIcon /></InputAdornment>)
                            }}
                            onChange={(e) => props.setSearchTo(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                e.stopPropagation();
                                }
                            }}
                            />
                        </ListSubheader>
                        {props.displayedOptionsTo.slice(0,20).map((option : any, i : number) => (
                            <MenuItem key={i} value={option.number}> {option.number} </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <TextField id={'search note'} sx={{ width : '15em' , marginRight : '1vh'}} value={props.search.note} onChange={(e) => props.handleChangeNote(e)} label="Note :"></TextField>
            <IconButton sx={{ marginLeft : 'auto'}} onClick={() => props.clearFilter()}><ClearIcon/></IconButton>
        </Box>
    )
}

export default Filters