/*
 * Date: 2024
 * Description: Endpoint table simplified logics
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules
import { IconButton , Box , Table , TableBody , TableCell , TableContainer ,  TableRow , TableHead, Tooltip , Paper , CircularProgress } from "@mui/material";

//Interfaces && types
import { i_endpoint , i_endpoint_tbl_props } from '../../interface/endpoint.interface';

//Icons
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';


const EndpointTbl = ( props : i_endpoint_tbl_props ) => { 
    return (
        <Box sx={{height : 950}}>
            { props.loading ?
                <Box component={'div'} > 
                    <CircularProgress size={100} color="inherit" sx={{  position: 'absolute',top:'50%' , left: '50%', transform: 'translate(-50%, -50%);', width : 100 , height : 100 , marginLeft: -5 }} />  
                </Box>
                   :
            <TableContainer component={Paper}  sx={{ maxHeight : 950 }}>
                <Table sx={{ minWidth: 500 }} aria-label="records table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width : 50}} align='center'>Alias</TableCell>
                            <TableCell style={{ width : 50}} align='center'>Sip url</TableCell>
                            <TableCell style={{ width : 180}} align='center'>Pair number</TableCell>
                            <TableCell style={{ width : 160}} align='center'>Notes</TableCell>
                            <TableCell style={{ width : 120}} align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row : i_endpoint) => (
                            <TableRow key={row.id} sx={{}}>
                                <TableCell component="th" style={{ width : 50}}>
                                    {row.alias}
                                </TableCell>
                                <TableCell component="th" style={{ width : 100}} align='center'>
                                    {row.sip_url}
                                </TableCell>
                                <TableCell style={{ width: 160 }}  align='center'>
                                    {row.pair_number}
                                </TableCell>
                                <TableCell style={{ width: 160 }}  align='center'>
                                    {row.notes}
                                </TableCell>
                                <TableCell style={{ width: 100 }}  align='center'>
                                    <Tooltip title={'Add a note'}>
                                        <IconButton color='primary' onClick={() => props.callback(row , 'edit')}><EditIcon/></IconButton>
                                    </Tooltip>
                                    <IconButton onClick={() => props.callback(row , 'info')}>
                                        <InfoIcon sx={{color : 'black'}}/>
                                    </IconButton>
                                </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                
                </Table>
            </TableContainer>
            }
        </Box>
    )
};

export default EndpointTbl