


//Modules
import { Box, Grid, Typography } from "@mui/material"


//Interfaces && types
import { i_endpoint_form_props } from "../../interface/endpoint.interface"
import { f_timestamp_to_date } from "../../utility/utility";



const EndpointInfo = ( props : i_endpoint_form_props) => {
    const F = props.form;
    const style = { marginBottom : '1vh' }
    const title_style = { fontWeight : '800'}
    return (
        <Box>
            <Grid container>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Alias :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography >{F.alias}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Username :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.username}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Application url :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.application}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Endpoint ID :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.endpoint_id}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Password :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.password}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Registered :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.registered}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Sip url :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.sip_url}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Pair number :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.pair_number}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Notes :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.notes}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Sub account :</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{F.sub_account}</Typography>
                </Grid>
                <Grid item xs={3} sx={style}>
                    <Typography sx={title_style}>Date added</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>{f_timestamp_to_date(F.timestamp,'short')}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}


export default EndpointInfo