/*
 * Date: 2024
 * Description: Form for user iterations
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { useState , useEffect , useContext, ReactElement } from 'react';
import { Paper , Box, Typography , Select , MenuItem  } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Interfaces
import { i_promise , i_alert , i_error_input } from '../../interface/utility.interface';
import { i_sent_user } from '../../interface/user.interface';

//Utils
import { f_generate_empty_promise } from '../../utility/utility';
import { Fetch } from '../api/fetch';

//Validation
import { m_valid_Cpassword, m_valid_email, m_valid_name, m_valid_password  , m_valid_phone} from '../utils/field.validation';

//Components
import PromptAlert from '../utils/alert';
import CustomInput from './custom.input';

//Styles
import { field_style } from '../styles/styles';

//Context
import { ManagementContext } from '../../context/management.context';
import { MainContext } from '../../context/main.context';


const UserForm = (props : any) : ReactElement => { 
    const { selected_user } = useContext(ManagementContext)
    const { setUser , user } = useContext(MainContext)
    const [ id , setId ] = useState<number>(0)
    const [ email , setEmail] = useState<string>('')
    const [ password , setPassword] = useState<string>('')
    const [ phone , setPhone ] = useState<string>('')
    const [ first_name , setFirstName ] = useState<string>('')
    const [ last_name , setLastName ] = useState<string>('')
    const [ type , setType ] = useState<string>('')
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ alert , setAlert ] = useState<boolean>(false)
    const [ alert_msg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'});
    const [ err_fn , setErrfn] = useState<i_error_input>({error : false , msg : ''});
    const [ err_ln , setErrln] = useState<i_error_input>({error : false , msg : ''});
    const [ err_phone , setErrphone] = useState<i_error_input>({error : false , msg : ''});
    const [ err_email , setErremail] = useState<i_error_input>({error : false , msg : ''});
    const [ confirmPassword ,setConfirmPassword] = useState<string>('')
    const [ err_password , setErrpassword] = useState<i_error_input>({error : false , msg : ''});
    const [ err_c_password , setErrCpassword] = useState<i_error_input>({error : false , msg : ''});
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
        if(err_password) setErrpassword({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value)
        if(err_phone) setErrphone({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value)
        if(err_fn) setErrfn({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value)
        if(err_ln) setErrln({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value)
        if(err_c_password) setErrCpassword({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        if(err_email) setErremail({error : false , msg : ''});
        handleAlertreset();
    }
    const handleChangeType = ( event : any) => {
        console.log(event)
        setType(event.target.value)
    }
    const handleAlertreset = () => {
        setAlert(false);
        setAlertmsg({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'})
    }
    const handleSubmit = async( event : any) => {
        event.preventDefault();
        var output : i_promise = f_generate_empty_promise();
        const valid_email = m_valid_email(email);
        var types = type
        if(selected_user.id === 1) types = 'Admin'
        const data : i_sent_user = {
            id: id,
            email : undefined,
            password : undefined,
            first_name : undefined,
            last_name : undefined,
            phone : undefined,
            type : types
        }
        if(valid_email !== '') return setErremail({ error : true , msg : valid_email})
        data.email = email
        if(password !== ''){
            const valid_password = m_valid_password(password);
            const valid_Cpassword = m_valid_Cpassword(password , confirmPassword)
            if(valid_password !== '') return setErrpassword({ error : true , msg : valid_password})
            data.password = password
            if(valid_Cpassword !== '') return setErrCpassword({ error : true , msg : valid_Cpassword})
        }        
        if(phone !== ''){
            const valid_phone = m_valid_phone(phone)
            if(valid_phone !== '') return setErrphone({error : true , msg : valid_phone})
            data.phone = phone
        }        
        if(first_name !== ''){
            const valid_fn = m_valid_name(first_name)
            if(valid_fn !== '') return setErrfn({error : true , msg : valid_fn})
            data.first_name = first_name
        }
        if(last_name !== ''){
            const valid_ln = m_valid_name(last_name)
            if(valid_ln !== '') return setErrfn({error : true , msg : valid_ln})
            data.last_name = last_name
        }
    
        setLoading(true)
        var method : 'POST' | 'PUT' = 'POST'
        if(data.id > 0) method = 'PUT'
        console.log(data , method)
        output = await Fetch("/api/users", data , method , props);
        setLoading(false)
        if(output.type === 'Success'){    
            if(output.data.id === user.id && method === 'PUT') setUser(output.data)
            props.setAlertmsg({msg : output.message , severity : 'success' , setAlert : setAlert , position : 'page'})
            props.setAlert(true)
            props.handleClose(output)
            props.handleRerender('user')
        }else{
            setAlertmsg({msg : output.message , severity : 'error' , setAlert : setAlert , position:'modal'})
            setAlert(true)
        }
        console.log(output)
    }
    useEffect(() => {
        setId(selected_user.id)
        setEmail(selected_user.email)
        setFirstName(selected_user.first_name)
        setLastName(selected_user.last_name)
        setPhone(selected_user.phone)
        setType(selected_user.type)
    },[selected_user])
    return (
        <Paper sx={{ padding:'1vh'}}>
            <Typography textAlign={'center'} sx={{fontWeight:'800', margin:'1vh'}}>{selected_user.id === 0 ? 'Create a new user' : 'Update user' }</Typography>
            <Box component={'form'} onSubmit={handleSubmit} autoComplete='off'>
                {alert ? <PromptAlert {...alert_msg}></PromptAlert> : null}     
                <CustomInput value={email} onChange={handleChangeEmail} err={err_email} name={'email'} type={'email'} style={field_style} required={true} fullWidth={true} complete={'current-email'} ></CustomInput>
                <CustomInput value={password} onChange={handleChangePassword} err={err_password} name={'password'} type={'password'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={confirmPassword} onChange={handleChangeConfirmPassword} err={err_c_password} name={'Confirm password'} type={'password'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <Typography>Optional fields **</Typography>
                <CustomInput value={phone} onChange={handleChangePhone} err={err_phone} name={'phone'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={first_name} onChange={handleChangeFirstName} err={err_fn} name={'first_name'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <CustomInput value={last_name} onChange={handleChangeLastName} err={err_ln} name={'last_name'} type={'text'} style={field_style} required={false} fullWidth={true} complete={'off'} ></CustomInput>
                <Select
                    labelId="Label"
                    id="type"
                    value={type}
                    label="Type"
                    onChange={handleChangeType}
                    fullWidth={true}
                    sx={{ color : 'black'}}
                >
                    <MenuItem value={"Admin"}>Admin , user type not implemented</MenuItem>
                </Select>
                <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color='primary'
                loading={loading}
                sx={{ mt: 3, mb: 2 }}
                >Submit</LoadingButton> 
            </Box>

        </Paper>
    )
}

export default UserForm