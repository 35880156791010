/*
 * Date: 2024
 * Description: All resuable constants
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Interfaces
import { i_records , i_filter_records } from "../interface/records.interface"
import { i_endpoint_errors , i_endpoint } from "../interface/endpoint.interface"
import { i_user } from "../interface/user.interface"
import { i_domain } from "../interface/domain.interface"

const default_target : i_records = {
    id : 0,
    call_uuid : '',
    add_time : 0,
    recording_url : "",
    recording_duration : 0,
    recording_start_ms : 0,
    recording_end_ms : 0,
    from_number : '',
    to_number : '',
    note : '',
    timestamp : 0,
    downloaded : 0,
    path : '',
    active : 0,
    input : 0
}
const default_search : i_filter_records = {
    pages : 0 , to_number : '' , from_number : '' , offset : 25 , timestamp : 'DESC' , note : '' , call_uuid : ''
}
const default_user : i_user = {
    first_name : '' , last_name : '' , email : '' , id : 0 , UUID : '' , type : 'Admin' , phone : '' , password : ''
}
const default_domain : i_domain = {
    address : '' , timestamp : 0 , id : 0 , note : ''
}
const default_errors : i_endpoint_errors = {
    id : '',
    pair_number : '',
    notes : ''
}
const default_endpoint : i_endpoint = {
    id : 0,
    endpoint_id: '',
    sip_url: '',
    alias: '',
    registered: '',
    application: '',
    username: '',
    sub_account: '',
    password: '',
    pair_number: '',
    notes: '',
    timestamp: 0
}

export { default_target, default_search , default_user , default_domain , default_endpoint , default_errors}