/*
 *
 * Filename: logs.table.tsx
 * Date: 2023-05-11
 * Description: Custom grid for logs
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules
import { f_timestamp_to_date  } from '../../utility/utility';
import { Box , Table , TableBody , TableCell , TableContainer ,  TableRow , TableHead, Paper ,  CircularProgress , Tooltip , Typography } from "@mui/material";
//Interfaces && types
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InfoIcon from '@mui/icons-material/Info';
import { i_logs, i_logs_filter } from '../../interface/logs.interface';
interface i_Custom_props {
    data : i_logs[],
    search : i_logs_filter,
    count : number,
    loading : boolean;
}
const FormatedUserAgent = ( useragent : string ) => {
    return (
        <Tooltip title={useragent}>
            <Typography>{useragent.substring(0,10) + '...'}</Typography>
        </Tooltip>
    )
}

const LogsTable = ( props : i_Custom_props) => { 
    return (
        <Box sx={{height : 950}}>
            { props.loading ?
                        <Box component={'div'} > 
                            <CircularProgress size={100} color="inherit" sx={{  position: 'absolute',top:'50%' , left: '50%', transform: 'translate(-50%, -50%);', width : 100 , height : 100 , marginLeft: -5 }} />  
                        </Box>

                   :
            <TableContainer component={Paper}  sx={{ maxHeight : 950 }}>
                <Table sx={{ minWidth: 500 }} aria-label="records table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width : 100}} align='center'>Date</TableCell>
                            <TableCell style={{ width : 50}} align='center'>Event</TableCell>
                            <TableCell style={{ width : 50}} align='center'>Output</TableCell>
                            <TableCell style={{ width : 100}} align='center'>Subject</TableCell>
                            <TableCell style={{ width : 160}} align='center'>User</TableCell>
                            <TableCell style={{ width : 160}} align='center'>Ip</TableCell>
                            <TableCell style={{ width : 100}} align='center'>Useragent</TableCell>
                        </TableRow>
                    </TableHead>
                <TableBody>
                    {props.search.offset > 0 && props.data.map((row : i_logs) => (
                        <TableRow key={row.id} >
                            
                            <TableCell component="th" style={{ width : 100 }}>
                                { f_timestamp_to_date(row.timestamp , 'Long')}
                            </TableCell>
                            <TableCell component="th" style={{ width : 50}}>
                                    { row.event }   
                            </TableCell>
                            <TableCell component="th" style={{ width : 50}}>
                                {row.type === 'Success' ? 
                                    <DoneIcon sx={{ color : 'green' }} /> :
                                        row.type === 'Failed' || row.type === 'Error' ?
                                    <ErrorIcon sx={{ color :'red' }} /> :
                                        row.type === 'Warning' ?
                                    <PriorityHighIcon sx={{ color : 'orange' }} /> :
                                        row.type === 'Info' ?
                                    <InfoIcon sx={{ color: 'purple' }} /> : null
                                }
                            </TableCell>
                            <TableCell component="th" style={{ width : 50}} align='center'>
                                    {row.caller}
                            </TableCell>
                            <TableCell style={{ width: 100 }}  align='center'>
                                {row.user_id !== null ? row.user_id: 'N/A'}
                            </TableCell>
                            <TableCell style={{ width: 100 }}  align='center'>
                                {row.ip !== null ? row.ip: 'N/A'}
                            </TableCell>
                            <TableCell style={{ width: 100 }}  align='center'>
                                {row.useragent !== null && row.useragent !== undefined ? FormatedUserAgent(row.useragent) : 'N/A'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            
                </Table>
            </TableContainer>
            }
        </Box>
    )
};

export default LogsTable;