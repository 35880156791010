/*
 *
 * Filename: simple.datagrid.tsx
 * Date: 2022-12-15
 * Description: Parent feeded datagrid.
 * Author: Philippe Leroux @ SKITSC
 *
 */
import { DataGrid } from "@mui/x-data-grid";
import { i_datagrid } from "../../interface/datagrid.interface";

const SimpleGrid = ( gridProps : i_datagrid) => {
    return (
        <DataGrid
            {...gridProps}
        />
    )
}

export default SimpleGrid