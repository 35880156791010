

//Modules
import CircularProgress from '@mui/material/CircularProgress';
import { ReactElement } from 'react';


const Loader = () : ReactElement => {
  return (
    <div style={{ position: 'absolute',  left: "50%", top:'50%', transform: 'translate(-50%,-50%)'}}>
        <CircularProgress color='primary' size={200}/>
    </div>

  );
}

export default Loader