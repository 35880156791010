/*
 *
 * Filename: app.tsx
 * Date: 2022-11-03
 * Description: Main component , where routes and context , props are initialized. Also checking if a token is serve.
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { Routes, Navigate , Route , HashRouter } from "react-router-dom";
import { useState , useEffect , useContext } from 'react'

//Styles
import './styles/main.css';

//Contexts
import { MainContext } from './context/main.context';
import { useHook } from './context/main.hook';

import { SocketProvider , SocketContext } from './context/socket.context';

//Utilities
import { Fetch } from './components/api/fetch';

//Interfaces
import { i_promise } from "./interface/utility.interface";

//Pages
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import OfflineBox from './pages/offline';
import Setting from './pages/setting';
import Welcome from './pages/welcome';
import Logs from './pages/logs';
import ForgotPassword from './pages/forgotpassword';
import Records from "./pages/records";
import Logout from './pages/logout';
import Endpoints from './pages/endpoints';

//Menu component
import Menu from './components/menu/menu';





function App() {
    const ContextState = useHook();
    const [offline , setOffline] = useState(false)    
    const socket = useContext(SocketContext);
    const useAuth = () => {
        if (ContextState.authenticated) {
            return true
        } else {
            return false
        }
    }        
    const f_auth_check = async() =>{
        var output : i_promise = await Fetch('/api/auth' ,null , 'POST' , ContextState)
        if(output.type === 'Error') {
            setOffline(true)
        }else{
            setOffline(false)
        }
        return output
    }
    useEffect(() => {
        const f_fetch_handler = async () => {
            const api_rep : i_promise = await f_auth_check();
            if (api_rep.type === 'Success') {
                ContextState.HandleLogin(api_rep.data);
                socket.emit('join', api_rep.data.type)
            }
            ContextState.setLoaded(true);
        }
        f_fetch_handler() //eslint-disable-next-line react-hooks/exhaustive-deps
    },[ContextState.authenticated])
    const PrivateRoute = ({ children }: any) => {
        const auth = useAuth();
        return auth ? children : <Navigate to="/" />;
    }
  return (
    <div className="app">
        <MainContext.Provider value={ContextState}>
            <SocketProvider>
                <HashRouter>
                        <Routes>
                            <>
                            <Route path='/' element={<Welcome {...ContextState}></Welcome>}></Route>
                            <Route path="/login" element={<Login {...ContextState} />} />
                            <Route path="/dashboard" element={<PrivateRoute><Menu {...ContextState} MyComponent={<Dashboard {...ContextState}/>}/></PrivateRoute>} />
                            <Route path="/records" element={<PrivateRoute><Menu {...ContextState} MyComponent={<Records {...ContextState}/>}/> </PrivateRoute>} />
                            <Route path="/endpoints" element={<PrivateRoute><Menu  {...ContextState} MyComponent={<Endpoints {...ContextState}/>}/></PrivateRoute>} />
                            <Route path="/setting" element={<PrivateRoute><Menu  {...ContextState} MyComponent={<Setting {...ContextState} />}/> </PrivateRoute>} />
                            <Route path="/logs" element={<PrivateRoute><Menu  {...ContextState} MyComponent={<Logs {...ContextState}/>}/> </PrivateRoute>} />
                            <Route path='/logout' element={<PrivateRoute><Logout  {...ContextState}></Logout></PrivateRoute>} />
                            <Route path='/offline' element={<OfflineBox offline={offline} />}></Route>
                            <Route path='/forgotpassword' element={<ForgotPassword {...ContextState} />}></Route>
                            </>
                        </Routes>
                    </HashRouter>
                </SocketProvider>
            </MainContext.Provider>
    </div>
  );
}

export default App;
