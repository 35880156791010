/*
 *
 * Filename: template.card.tsx
 * Date: 2022-11-03
 * Description: Fake data when the backend does not show data ( 'Test card component' )
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Interfaces
import { i_card_latest , i_card_login , i_card_count , i_card_data } from "../../interface/card.interface"

const CLogin : i_card_login = {
    title : 'Latest user to log in',
    date : 22222222,
    email : 'admin@admin.com'
}
const CData : i_card_data = {
    today_call : 4,
    week_call : 29,
    month_call : 200,
    title : 'Call of the month'
}
const CCount : i_card_count = {
    title : 'High numbers , most called and caller',
    count : 29873,
    called : '514-616-0000',
    called_count : 2,
    caller : '514-444-4444',
    caller_count : 4,
}
const CLatest : i_card_latest = {
    title : 'Latest call recorded',
    from : '514-111-1111',
    to : '514-111-1112',
    duration : 30,
    timestamp : 293298293
}
export { CLogin , CData , CCount , CLatest }