/*
 *
 * Filename: card.tsx
 * Date: 2022-11-04
 * Description: Card component for the dashboard
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import * as React from 'react';
import { Card  , CardContent , Typography , Skeleton , Box } from '@mui/material';

const title_style = {
    fontSize : 20,
    marginBottom : 2
}
const CardLatest = (props : any) => {
    const data = props.CLatest
    const card = (
        <React.Fragment>
            <CardContent>
                <Typography  textAlign={'center'} sx={title_style} color="primary" gutterBottom>
                {data.title}
                </Typography>
                <Box sx={{ fontSize: 16 , mb: 1.5 }} component="div" color="secondary">
                From : <Typography sx={data_style}>{data.from}</Typography>
                </Box>
                <Box sx={{ mb: 1.5 }} color="secondary">
                To : <Typography sx={data_style}>{data.to}</Typography>
                </Box>
                <Box sx={{ mb: 1.5 }} color="secondary">
                Date : <Typography sx={data_style}>{data.timestamp}</Typography>
                </Box>
                <Box color="secondary">
                Duration <Typography sx={data_style}>{data.duration}</Typography>
                <br />
                </Box>
            </CardContent>
        </React.Fragment>
      );
  return (
    <Box sx={{ maxWidth:'600px' , height: '300px'}}>
    {props.loading ? <Skeleton height='250px'/> : <Card sx={{ height:'225px'}} >{card}</Card> } 
</Box>
  );
}
const data_style = {
    display : 'inline',
    fontWeight : 800
}
const CardCount = ( props : any ) => { 
    const data = props.CCount
    const card = (
        <React.Fragment>
            <CardContent>
                <Typography textAlign={'center'} sx={title_style} color="primary" gutterBottom>
                {data.title}
                </Typography>
                <Box sx={{ fontSize: 16 , mb: 1.5 }} color="secondary" component="div">
                Total records : <Typography sx={data_style}>{data.count}</Typography>
                </Box>
                <Box component="div" sx={{ mb: 1.5 }} color="secondary">
                Most frequent called : <Typography sx={data_style}>{data.called} ({data.called_count})</Typography> 
                </Box>
                <Box component="div" color="secondary">
                Most frequent caller : <Typography sx={data_style}>{data.caller} ({data.caller_count})</Typography>
                <br />
                </Box>
            </CardContent>

        </React.Fragment>
      );
  return (
    <Box sx={{ maxWidth:'600px' , height: '300px'}}>
    {props.loading ? <Skeleton height='250px'/> : <Card sx={{ height:'225px'}} >{card}</Card> } 
</Box>
  );
}
const CardLogin = ( props : any ) => {
    const data = props.CLogin
    const card = (
        <React.Fragment>
            <CardContent>
                <Typography  textAlign={'center'} sx={title_style} color="primary" gutterBottom>
                {data.title}
                </Typography>
                <Box component="div" sx={{ fontSize: 16 , mb: 1.5 }} color="secondary">
                Email : <Typography sx={data_style}> {data.email}</Typography>
                </Box>
                <Box component="div" sx={{ mb: 1.5 }} color="secondary">
                Date : <Typography sx={data_style}>{data.date}</Typography>
                </Box>
                <Box component="div">
                    <br />
                </Box>
            </CardContent>

        </React.Fragment>
        );
    return (
        <Box sx={{ maxWidth:'600px' , height: '300px'}}>
             {props.loading ? <Skeleton height='250px'/> : <Card sx={{ height:'225px'}} >{card}</Card> } 
        </Box>
    );
}
const CardData = ( props : any ) => {
    const data = props.CData
    const card = (
        <React.Fragment>
            <CardContent>
                <Typography  textAlign={'center'} sx={title_style} color="primary" gutterBottom>
                {data.title}
                </Typography>
                <Box sx={{ fontSize: 16 , mb: 1.5 }} component="div" color="secondary">
                Call recorded today : <Typography sx={data_style}>{data.today_call}</Typography>
                </Box>
                <Box sx={{ mb: 1.5 }} color="secondary">
                Call recorded this week : <Typography sx={data_style}>{data.week_call}</Typography>
                </Box>
                <Box component="div" color="secondary">
                Call recorded this month : <Typography sx={data_style}>{data.month_call}</Typography>
                <br />
                </Box>
            </CardContent>

        </React.Fragment>
      );
  return (
    <>
        <Box sx={{ maxWidth:'600px' , height: '300px'}}>
             {props.loading ? <Skeleton height='250px'/> : <Card sx={{ height:'225px'}} >{card}</Card> } 
        </Box>
    </>
  );
}

export { CardLatest , CardCount , CardLogin , CardData }