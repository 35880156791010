/*
 *
 * Filename: alert.tsx
 * Date: 2022-11-03
 * Description: Alert component to prompt user for new events.
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { ReactElement } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//Interfaces
import { i_alert } from '../../interface/utility.interface';


const PromptAlert = (props : i_alert) : ReactElement => {
    const handleAlert = () => {
        props.setAlert(false);
    }
    const login_sx = {
        marginTop : '4vh',
        width : '35vh',
        position : 'absolute',
        right: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
    const modal_sx = {

    }
    const page_sx = {
        width : '35vh',
        position : 'absolute',
        marginLeft : '-2vh',
        right: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
  return (
    <Stack sx={props.position === 'page' ? page_sx : props.position === 'login' ? login_sx : modal_sx} spacing={0}>
      <Alert severity={props.severity} onClose={() => handleAlert()}>{props.msg}</Alert>
    </Stack>
  );
}
export default PromptAlert