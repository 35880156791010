/*
 * Date: 2024
 * Description: yes no form with function cast on yes
 * Author: Philippe Leroux @ SKITSC
 */

//Modules
import { ReactElement } from "react"
import { LoadingButton } from "@mui/lab"
import { Button , Typography , Box } from "@mui/material"

//Interfaces
import { i_yesno } from "../../interface/utility.interface"


const YesNoForm = ( { handleClose , Action , title , loading } : i_yesno) : ReactElement => {
    return (
        <>
            <Typography textAlign={'center'} sx={{ fontWeight : 800 }}>{title}</Typography>
            <Box sx={{margin : '2vh' , display : 'flex' , alignItems : 'center' , justifyContent:'center'}}>
                <LoadingButton variant="contained" color='primary' sx={{mr : 2 , fontWeight:800}} onClick={() => Action()} loading={loading}>Yes</LoadingButton>
                <Button variant="contained" sx={{ backgroundColor : 'lightgray' , fontWeight:800}}  onClick={() => handleClose()}>No</Button>
            </Box>
        </>
    )
}

export default YesNoForm