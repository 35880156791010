/*
 *
 * Filename: logs.tsx
 * Date: 2023-05-11
 * Description: Page where the user can filters and navigate threw all logs..
 * Author: Philippe Leroux @ SKITSC
 *
 */
import { c_theme } from "../theme/custom.theme"
import { ThemeProvider , Container , Typography , Paper , Box } from "@mui/material"
import { useState , useEffect  } from 'react'
//Components and utilities
import { Fetch } from "../components/api/fetch"
import LogsTable from "../components/table/logs.table"
import PagesComponent from "../components/table/fitler.table.pagination"
import { callers , types } from "../utility/utility"
//Interfaces && types
import { i_logs_search } from "../interface/logs.interface"
import { i_promise } from "../interface/utility.interface"
import { i_logs } from "../interface/logs.interface"
import { t_log_output , t_log } from "../types/types"
import LogsFilters from "../components/table/logs.filter"
const Logs = ( props : any) => {
    const default_search : i_logs_search = {
        page : 0 , type : 'Any' , caller : 'Login' , offset : 25 , timestamp : 'DESC'
    }
    const [ data , setData ] = useState<i_logs[]>([])    
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ search , setSearch ] = useState<i_logs_search>( default_search )
    const [ count , setCount ] = useState<number>(0)
    const f_encodeQueryData = ( search : i_logs_search) =>  { return new URLSearchParams({...search, page : search.page.toString() , offset : search.offset.toString()}) }
    useEffect(() => {
        setLoading(true)
        const f_fetch_logs = async() => {
            const output : i_promise = await Fetch('/api/logs/filters?' + f_encodeQueryData( search ),null,'GET' , props)
            if(output.type === 'Success') {
                setData(output.data.logs)
                setCount(output.data.count)
            }
            setLoading(false)
        }
        f_fetch_logs();
    },[search , props])
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) : void => { 
        const new_search : i_logs_search = {...search}
        new_search.page = newPage
        setSearch(new_search)
    };
    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
        const new_search : i_logs_search = {...search}
        new_search.page = 0
        new_search.offset = parseInt(event.target.value, 10)
        setSearch(new_search)
    };
    const handleTimestamp = () : void => {
        const current_search = {...search}
        if(search.timestamp === 'ASC'){
            current_search.timestamp = 'DESC'
            setSearch(current_search)
        }else{
            current_search.timestamp = 'ASC'
            setSearch(current_search)
        }
    }
    const handleChangeCaller = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
        const new_search : i_logs_search = {...search}
        if(callers.includes(event.target.value as t_log)){
            new_search.caller = event.target.value as t_log
            setSearch(new_search)
        }
    }
    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
            const new_search : i_logs_search = {...search}
            if(types.includes(event.target.value as t_log_output)){
                new_search.type = event.target.value as t_log_output
                setSearch(new_search)
            }
    }
    const clearFilter = () : void => setSearch(default_search)
      const Tableprops = {
        search : search,
        count : count,
        data : data,
        setData : setData,
        loading : loading,
        page : search.page,
        rowsPerPage : search.offset,
        onPageChange : handleChangePage,
        handleChangeRowsPerPage : handleChangeRowsPerPage,
        clearFilter : clearFilter,
        handleTimestamp : handleTimestamp,
        handleChangeCaller : handleChangeCaller,
        handleChangeType : handleChangeType
      }
    return (
        <ThemeProvider theme={c_theme}>
                <Container sx={{ marginTop:5}}>
                        <Paper elevation={5} sx={{padding : '3vh' , mt : '3vh' ,height: 1080 }}>
                            <Typography sx={{ fontWeight: 800 , marginBottom : '1vh'}}>All Logs</Typography>
                            <Box>
                                <LogsFilters {...Tableprops}></LogsFilters>
                                <LogsTable {...Tableprops}></LogsTable>
                                <PagesComponent {...Tableprops}></PagesComponent>
                            </Box>
                        </Paper>
                </Container>
        </ThemeProvider>
    )
}
export default Logs