const field_style = {
    backgroundColor : 'white'
}
const none_style = {
    display : 'none'
}
const sticky_menu = {
    width: '100%' , backgroundColor:'#333' , 
  marginBottom : '4vh'
}
const normal_menu = {
    width: '100%' , backgroundColor:'#333' , marginBottom : '4vh'
}
const is_notif = {
    marginLeft : 'auto',
    color : 'white'
}
const tab_style = {
    color : 'white'
}
const tab_float = {
    marginLeft: 'auto',
    color : 'white'
}
const icon_style = {
    color : 'red',
    marginLeft : 'auto',
    cursor : 'pointer',
    marginTop : '0.9vh'
}

export { field_style , none_style , sticky_menu , normal_menu , is_notif , tab_style , icon_style , tab_float }