/*
 *
 * Filename: custom.theme.ts
 * Date: 2022-11-05
 * Description: Theme that can be used to change colors.
 * Author: Philippe Leroux @ SKITSC
 *
 */
import { createTheme } from '@mui/material/styles'
declare module '@mui/material/styles' {
    interface Theme {
      status: {
        danger: React.CSSProperties['color'];
      };
    }
  
    interface Palette {
      neutral: Palette['primary'];
    }
    interface PaletteOptions {
      neutral: PaletteOptions['primary'];
    }
  
    interface PaletteColor {
      darker?: string;
    }
    interface SimplePaletteColorOptions {
      darker?: string;
    }
    interface ThemeOptions {
      status: {
        danger: React.CSSProperties['color'];
      };
    }
  }

  const c_theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#d9b451;',
        darker: '#053e85',
      },
      secondary:{
        main: '#000',
        darker: '#053e85',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
    },
  });
  
export { c_theme }